<template>
  <div class="app-container">
    <eHeader ref="header" :query="query" @toQuery="toQuery" @clear="toClearInit" />
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="businessType" label="账户类型" width="80">
        <template slot-scope="scope">
          <span>{{scope.row.businessType==1?"Social":"MAM"}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="login" label="信号"  width="120">
        <template slot-scope="scope">
          <span class="span-item">{{scope.row.alias}}</span>
          <span class="span-item">{{scope.row.login}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="signalLogin" label="收费方式" width="130">
        <template slot-scope="scope">
          <template v-if="scope.row.isFree">
            <span>免费订阅</span>
          </template>
          <template v-else>
            <span class="span-item" >{{scope.row.price}}</span>
            <span v-if="scope.row.businessType==1" class="span-item">{{scope.row.extraPrice}}</span>
            <span v-else class="span-item">
              <span v-if="scope.row.extraPrice==0  && scope.row.isFree" class="span-item" >表现费：无</span>
              <el-tooltip v-else class="item" effect="dark" placement="top-start">
                <div slot="content">
                    <div v-for="(item,index) in scope.row.extraPrice"  :key="index">
                      {{item}} <br/>
                    </div>
                  </div>
                <span class="span-item" >表现费：...</span>
              </el-tooltip>
            </span>
          </template>
        </template>
      </el-table-column>
      <el-table-column prop="strategy" label="策略说明" width="130" :show-overflow-tooltip="true"/>
      <el-table-column prop="equity" label="净值"/>
      <el-table-column prop="balance" label="余额"/>
      <el-table-column prop="deposit" label="总入金"/>
      <el-table-column prop="withdraw" label="总出金"/>
      <el-table-column prop="transferPeriod" label="交易周期"/>
      <el-table-column prop="profitRate" label="收益率"/>
      <el-table-column prop="maxRetracement" label="最大回撤"/>
      <el-table-column prop="copyNum" label="跟随人数"/>
      <el-table-column prop="copyAmount" label="跟随金额"/>
    </el-table>
    <!--分页组件-->
    <public-pagination
            :total-page="total"
            :current-page="page"
            @pageChange="pageChange"
            @sizeChange="sizeChange"
    ></public-pagination>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import eHeader from '@/components/copy/singal/header'
export default {
  name:'singal',
  components: { eHeader },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      sup_this: this,
      dialogFormVisible:false,
      flatformdata:[],
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = '/crm/querySignalList'
      const sort = 'id,desc'

      this.params = {
        page: this.page,
        size: this.size,
        signalInfo: this.query.signalInfo,
        sort: sort,
      }
      if (this.$refs.header.$refs.accountType.businessType) {
          this.params['businessType'] = this.$refs.header.$refs.accountType.businessType
      }
      return true
    },
    toClearInit() {
      this.query = {}
      if (this.$refs.header.$refs.accountType.businessType) {
          this.$refs.header.$refs.accountType.businessType=''
      }
      this.init()
    }
  }
}
</script>

<style scoped>
.link-sum{
  color:blue;
  text-decoration:underline;
  cursor:pointer;
}
.link-sum:hover {
  text-decoration:underline;
   color:red;
}
.span-item{
  display: block;
}
</style>
