<template>
  <div class="head-container">
    <!-- 搜索 -->
    <account-type ref="accountType" @toQuery="toQuery"></account-type>
    <el-input v-model="query.signalInfo" clearable placeholder="信号源昵称/MT4" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
    <el-button
            class="filter-item"
            size="mini"
            @click="toClearInit"
            type="primary"
            icon="el-icon-close"
    >清空</el-button>
  </div>
</template>

<script>
  // copySiteId;  //跟随者站点id
  // copyName;  //跟随者名称
  // copyLogin; //跟随者MT4账号
  // signalName; //交易员名称
  // signalLogin;  //交易员MT4账号
  // status; //0-有效 1-失效 2-取消订阅 3-通讯失败
  // beginDate; //查询开始时间
  // expireDate; //查询结束时间
import checkPermission from '@/utils/permission' // 权限判断函数
import accountType from '@/components/copy/accountType'
export default {
  components: { accountType },
  props: {
    query: {
      type: Object,
      required: true
    },
  },
  methods: {
    toQuery() {
      this.$emit('toQuery');
    },
    toClearInit(){
      this.$emit('clear');
    },
  }
}
</script>
